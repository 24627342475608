import React, { useState } from 'react';
import axios from 'axios';
import jwt from 'jsonwebtoken';
import { useForm } from 'react-hook-form';

import Layout from '../layout';
import SEO from '../components/seo';
import Container from '../components/container';
import Grid from '../components/grid';

// import Scene from '../images/scene-contact-03b.inline.svg'; // TODO: 06 or 3b
import Scene from '../images/people-02b.inline.svg'; // TODO: 06 or 3b
import loadingSVG from '../images/loading-02.svg';

const ContactUsPage = () => {
  const { register, handleSubmit, errors } = useForm();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');

  const handleFormSubmit = async (data, e) => {
    setLoading(true);

    data.id = 1;
    const token = jwt.sign(data, process.env.JWT_SECRET, { expiresIn: '30m' });

    await axios
      .post(`${process.env.GATSBY_API_URL}/contact`, { token })
      .then((res) => {
        if (res.data.success) {
          setLoading(false);
          setSuccess(`Message has been sent! We'll get back to you as soon as possible.`);
          e.target.reset();
        }
      })
      .catch((err) => {
        // TODO: handle it if it returns an object (e.g., sendgrid api key is invalid)
        console.log('error', err.response);
        setLoading(false);
        setError(err.response ? err.response.data : 'Failed to connect. Please try again later.');
      });
  };

  return (
    <Layout>
      <SEO
        title="Contact Us"
        description={`Are you looking to get a custom-built website or have a question? Send us a message and we'd be happy to help out!`}
      />

      <Container>
        <Grid className="my-6 md:my-16">
          <div className="flex flex-col justify-center col-span-12 lg:col-span-5">
            <h1 className="text-3xl font-black leading-tight xl:text-4xl">Chat with our team</h1>

            <p className="mt-2 text-xl text-gray-700">
              Are you looking to get a custom-built website or have a question? Send us a message
              and we'd be happy to help out!
            </p>

            <form className="max-w-sm mt-6" onSubmit={handleSubmit(handleFormSubmit)}>
              <label className="block">
                <div className="font-semibold">Name</div>

                <input
                  className={
                    'p-3 bg-gray-200 rounded mt-1 w-full' +
                    (errors.name ? ' border-red-700 border-2' : '')
                  }
                  name="name"
                  ref={register({ required: true })}
                  placeholder="Jane Doe"
                />

                {errors.name && (
                  <div className="mt-1 text-sm font-semibold text-red-700">Name is required.</div>
                )}
              </label>

              <label className="block mt-4">
                <div className="font-semibold">Email</div>

                <input
                  className={
                    'p-3 bg-gray-200 rounded mt-1 w-full' +
                    (errors.email ? ' border-red-700 border-2' : '')
                  }
                  name="email"
                  ref={register({
                    required: true,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'Invalid email address.',
                    },
                  })}
                  placeholder="john@example.com"
                />

                {errors.email && (
                  <div className="mt-1 text-sm font-semibold text-red-700">
                    {errors.email?.message || 'Email address is required.'}
                  </div>
                )}
              </label>

              <label className="block mt-4">
                <div className="font-semibold">Message</div>

                <textarea
                  className={
                    'w-full p-3 mt-1 bg-gray-200 rounded' +
                    (errors.message ? ' border-red-700 border-2' : '')
                  }
                  name="message"
                  ref={register({ required: true })}
                  rows="5"
                  placeholder="Information about the website you'd love to have"
                />

                {errors.message && (
                  <div className="text-sm font-semibold text-red-700">Message is required.</div>
                )}
              </label>

              {success && (
                <div className="px-4 py-3 my-4 font-semibold leading-snug text-green-800 bg-green-200 border-l-4 border-green-600 rounded-tr rounded-br">
                  {success}
                </div>
              )}

              {error && (
                <div className="px-4 py-3 my-4 font-semibold leading-snug text-red-700 bg-red-200 border-l-4 border-red-600 rounded-tr rounded-br">
                  {error}
                </div>
              )}

              <div className="mt-4">
                <button
                  type="submit"
                  className="flex items-center px-5 py-3 font-semibold text-white bg-blue-500 rounded-md shadow disabled:cursor-not-allowed disabled:opacity-75"
                  disabled={loading}
                >
                  {loading ? (
                    <img src={loadingSVG} className="w-5 h-5" alt="" />
                  ) : (
                    <svg fill="currentColor" className="w-5 h-5" viewBox="0 0 20 20">
                      <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                      <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
                    </svg>
                  )}

                  <span className="ml-2">{loading ? 'Sending...' : `Let's talk`}</span>
                </button>
              </div>
            </form>
          </div>

          <div className="flex flex-col justify-center col-span-12 mt-12 lg:col-span-6 lg:col-start-7 lg:mt-0">
            <Scene className="w-full h-auto" />
          </div>
        </Grid>
      </Container>
    </Layout>
  );
};

export default ContactUsPage;
